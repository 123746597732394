export default defineNuxtRouteMiddleware((to) => {
  const routesToNotNavigate = [
    'blog-title___es',
    'blog-title___en',
    'blog___es',
    'blog___en',
    'casos-de-sucesso___en',
    'casos-de-sucesso___es',
    'carreiras___en',
    'carreiras___es',
    'programa-de-formacao___en',
    'programa-de-formacao___es',
  ]

  const nameRoute: any = to.name

  if (routesToNotNavigate.includes(nameRoute)) {
    return navigateTo('/', { redirectCode: 301 })
  }

  if (to.fullPath == '/sobre/') {
    return navigateTo('/quem-somos', { redirectCode: 301 })
  }

  if (to.fullPath == '/trabalhe-conosco/todas-as-vagas/') {
    return navigateTo('/', { redirectCode: 301 })
  }
})
