import { OnDemand } from '../helpers/onDemand'

export default defineNuxtPlugin((nuxtApp) => {
  const loader = new OnDemand('https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js')

  nuxtApp.provide('rdstation', {
    plot(formId, code) {
      loader.load(() => {
        new window.RDStationForms(formId, code).createForm()
      })
    },
  })
})
