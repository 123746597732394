<template>
  <component
    :is="component"
    v-if="slot"
    :to="to"
    :disabled="disabled ? true : null"
    :class="classObject"
    aria-label="button"
  >
    <slot />
  </component>
</template>

<script lang="ts">
enum Colors {
  Primary = 'primary',
  Secondary = 'secondary',
  Third = 'third',
  Fourth = 'fourth',
  Fifth = 'fifth',
  Sixth = 'sixth',
}
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    color?: string
    to?: any
    href?: String
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const slots = useSlots()
const slot = computed(() => slots.default)
const component = computed(() => {
  if (props.to) return resolveComponent('NuxtLink')
  return 'button'
})

const classObject = computed(() => ({
  primary: props.color == Colors.Primary,
  secondary: props.color == Colors.Secondary,
  third: props.color == Colors.Third,
  fourth: props.color == Colors.Fourth,
  fifth: props.color == Colors.Fifth,
  sixth: props.color == Colors.Sixth,
}))
</script>

<style lang="scss" scoped>
button,
a {
  display: flex;
  border-radius: 25px;
  font: normal normal normal 16px/19px Montserrat;
  padding: 4px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &.primary {
    color: #1f214a;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #1f214a;

    &:hover {
      background-color: #1f214a;
      border: 2px solid #1f214a;
      color: white;
    }
  }

  &.secondary {
    color: rgb(255, 193, 41);
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgb(255, 193, 41);

    &:hover {
      background-color: rgb(255, 193, 41);
      border: 2px solid rgb(255, 193, 41);
      color: #1f214a;
    }
  }

  &.third {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgb(143, 0, 69);
    color: rgb(143, 0, 69);

    &:hover {
      background-color: rgb(143, 0, 69);
      border: 2px solid rgb(143, 0, 69);
      color: white;
    }
  }

  &.fourth {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #00b4ff;
    color: #00b4ff;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
      background-color: #00b4ff;
      border: 2px solid #00b4ff;
      color: white;
    }
  }

  &.fifth {
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
      background-color: #ffb500;
      border-color: #ffb500;
      color: black;
      font-weight: 700;
    }
  }

  &.sixth {
    background: #960b38 0% 0% no-repeat padding-box;
    border-radius: 100px;
    opacity: 1;
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0.32px;
    color: #ffffff;
    height: 56px;
    border: none;
  }
}
</style>
