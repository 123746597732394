<template>
  <NuxtLayout>
    <NuxtLoadingIndicator :color="'#ffc129'" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup></script>

<style lang="scss"></style>
