<template>
  <div class="headerPageNotFound d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 d-flex justify-content-center align-items-center flex-column">
          <NuxtImg
            src="images/img404.webp"
            alt="notfound-page"
            class="headerPageNotFoundContent__img"
            quality="100"
            loading="lazy"
            sizes="xs:100vw lg:1000px"
          />
          <h1 v-if="error.statusCode == 404" class="headerPageNotFoundContent__title">
            {{ $t('message.pageNotFound.text1') }}
          </h1>
          <h1 v-if="error.statusCode != 404" class="headerPageNotFoundContent__title">
            Site em manutenção! Voltaremos em breve
          </h1>
          <span v-if="error.statusCode == 404" class="headerPageNotFoundContent__span">404</span>
          <VButton color="third" class="headerPageNotFoundContent__button" @click="handleError">
            {{ $t('message.pageNotFound.link') }}
          </VButton>

          <p v-if="env == 'development'" class="mt-5">
            <textarea v-model="getError" style="width: 600px; height: 200px"></textarea>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
})

const env = ref(process.env.NODE_ENV)

const handleError = () => clearError({ redirect: '/' })
const getError = computed(() => JSON.stringify(props.error))
</script>

<style scoped lang="scss">
.headerPageNotFound {
  height: 100vh;
  background-color: #eee;
  z-index: 1;
}
.headerPageNotFoundContent__title {
  font: normal normal bold 40px/60px Montserrat;
  letter-spacing: 0px;
  color: #1f214a;
  max-width: 80%;
  text-align: center;
  z-index: 99;
  margin-bottom: 100px;
}

.headerPageNotFoundContent__button {
  font: normal normal bold 20px/60px Montserrat;
  z-index: 2;
}

.headerPageNotFoundContent__span {
  text-align: center;
  font-size: 200px;
  line-height: 240px;
  font-weight: 100 !important;
  letter-spacing: 30px;
  color: $color-third;
}

.headerPageNotFoundContent__img {
  width: 600px;
  position: absolute;
  right: 0px;
  z-index: 0;
  opacity: 0.05;
}
</style>
